<script>
/**
 * Before using this component, remember that the
 * tracker configuration needs to be initialized
 * unless you want to use the default config
 */
import axios from 'axios';
import Tracker from '~lib/tracker';

export default {
  name: 'RenderlessPixelTracker',
  props: {
    data: {
      type: Object,
      default: null,
      // validator: obj => obj.evn !== undefined,
    },
    threshold: {
      type: [Array, Number],
      default: 1.0,
    },
    customFilename: {
      type: String,
      default: '',
    },
    root: {
      type: null,
      default: null,
    },
    observe: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      tracked: false,
      observer: null,
    };
  },
  computed: {
    fileNameAppended() {
      return `${this.customFilename || this.data.evn}_${Date.now()}`;
    },
    observerConfig() {
      return {
        root: this.root,
        rootMargin: '0px',
        threshold: this.threshold,
      };
    },
  },
  watch: {
    observe(value) {
      if (this.observer) {
        if (value) {
          this.observer.observe(this.$el);
        } else {
          this.observer.unobserve(this.$el);
        }
      }
    },
    visible(value) {
      if (value && !this.tracked) {
        try {
          if (this.data) {
            const gifUrl = Tracker.pixel(this.data, this.fileNameAppended);
            axios.get(gifUrl);
          }

          this.tracked = true;
          this.$emit('visible');
        } catch (e) {
          /** */
        }
      }
    },
  },
  beforeMount() {
    const debug = process.env.NODE_ENV !== 'production';
    const hasMultipleChildren = this.$slots.default.length > 1;
    if (hasMultipleChildren) {
      if (debug) {
        throw new Error(
          "Component's slot should contain exactly one root. If you have multiple elements, please wrap them under one root element.",
        );
      }
    } else {
      this.observer = new IntersectionObserver(this.toggleTracker, this.observerConfig);
    }
  },
  mounted() {
    if (this.observe) {
      this.observer.observe(this.$el);
    }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.$el);
    }

    if (this.$__pixelComponent) {
      this.$__pixelComponent.$destroy();
    }
  },
  methods: {
    toggleTracker([entry]) {
      this.visible = entry.isIntersecting;

      if (this.visible) {
        this.observer.unobserve(this.$el);
      }
    },
  },
  render() {
    return this.$slots.default[0] || null;
  },
};
</script>
