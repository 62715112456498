<template lang="pug">
.c-product-details-listing
  transition-group(name='fade')
    bl-product-card-placeholder-mv(v-if='isLoading', key='loading')
    template(v-else)
      .c-product-details-listing__heading(key='active-heading')
        h2.c-product-list-header.u-txt--medium.u-txt--bold {{ title }}
      .c-product-details-listing__body(key='active-body')
        bl-carousel-mv(
          :slides-to-show='6',
          :slides-to-scroll='6',
          :infinite='false',
          :dots='false'
        )
          bl-carousel-slide-mv(v-for='(product, idx) in collection', :key='product.id')
            bl-renderless-pixel-tracker(
              :threshold='[0, 0.5, 1.0]',
              :custom-filename='impressionName',
              :data='pixelTrackerData[idx]',
              :observe='!!impressionName'
            )
              bl-product-card-mv(
                :product='product',
                @click='handleProductClick(product)',
                :is-finished-fetch-badge-toggle='isFinishedFetchBadgeToggle',
                :is-super-seller-new-badge-active='isSuperSellerNewBadgeActive'
              )
</template>

<script>
import { BlCarouselMv, BlCarouselSlideMv } from '~shared-js/components/BlCarouselMv';
import BlProductCardMv from '~shared-js/components/BlProductCardMv';
import BlRenderlessPixelTracker from '~shared-js/components/BlRenderlessPixelTracker';
import BlProductCardPlaceholderMv from '~shared-js/components/BlProductCardPlaceholderMv';
import getSuperSellerBadgePromise from '~shared-js/helpers/super-seller-badge-promise';

export default {
  name: 'BlProductScrollerOv',

  components: {
    BlCarouselMv,
    BlCarouselSlideMv,
    BlProductCardMv,
    BlRenderlessPixelTracker,
    BlProductCardPlaceholderMv,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    collection: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    impressionName: {
      type: String,
      default: '',
    },
    impressionTrackerData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isSuperSellerNewBadgeActive: false,
      isFinishedFetchBadgeToggle: false,
    };
  },

  computed: {
    pixelTrackerData() {
      return this.collection.map(product => ({
        ...this.impressionTrackerData,
        product_id: product.id,
        seller_id: product.store && product.store.id.toString(36),
      }));
    },
  },

  mounted() {
    this.getNewSuperSellerBadgeStatus();
  },

  methods: {
    handleProductClick(product) {
      this.$emit('productClick', product);
    },

    getNewSuperSellerBadgeStatus() {
      const promise = getSuperSellerBadgePromise();
      promise
        .then(res => {
          this.isSuperSellerNewBadgeActive = res.data[0] ? res.data[0].active : false;
        })
        .finally(() => {
          this.isFinishedFetchBadgeToggle = true;
        });
    },
  },
};
</script>
